<template>
  <span class="title" :style="{ '--width': width }">
    <span :class="{ 'title-text': title.length > 30 }">{{ title }}</span>
  </span>
</template>

<script>
export default {
  props: {
    width: String,
    title: String
  }
};
</script>

<style lang="less">
@keyframes breadcrumbTitle {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-100% + var(--width)));
  }
  100% {
    transform: translateX(0);
  }
}

.title {
  vertical-align: top;
  display: inline-block;
  max-width: var(--width);
  overflow: hidden;

  .title-text {
    margin-left: 0px;
    white-space: nowrap;
    width: fit-content;
    vertical-align: top;
    display: inline-block;
    &:hover {
      animation: breadcrumbTitle 10s infinite;
    }
  }
}
</style>
