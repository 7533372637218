<template>
  <div class="layout-content">
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
        <el-breadcrumb-item :to="{ path: '/note' }">博客列表</el-breadcrumb-item>
        <el-breadcrumb-item>
          <ScrollText :title="title || '未命名'" width="500px" />
        </el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row type="flex" style="height: 100%;">
      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" class="text">
        <el-row type="flex" justify="space-between" align="middle">
          <el-col :xs="24" :md="20">
            <h1 class="blog-title">{{ title || "未命名" }}</h1>
          </el-col>
          <el-col :xs="24" :md="4" style="text-align: right;">
            <router-link :to="`/note/detail/${$route.params.id}/edit`">
              <el-button size="small"> <i class="el-icon-edit"></i> 编辑 </el-button>
            </router-link>
          </el-col>
        </el-row>
        <el-row class="blog-create-date">创建日期：{{ formatDate(createAt) }}</el-row>
        <el-row>
          <template v-if="tags">
            <el-tag
              class="blog-tag"
              size="mini"
              v-for="(tag, index) in tags"
              color="#eee"
              :key="index"
              >{{ tag }}</el-tag
            >
          </template>
        </el-row>
        <el-row class="blog-content" v-highlight>
          <mavon-editor
            id="editor"
            defaultOpen="preview"
            codeStyle="atom-one-dark-reasonable"
            previewBackground="#fff"
            :boxShadow="false"
            :autofocus="false"
            :toolbarsFlag="false"
            :editable="false"
            :subfield="false"
            :value="content"
          />
        </el-row>
        <el-row>
          <el-button class="zan bc bc-like">1</el-button>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="nav">
        <el-input placeholder="搜索" v-model="search">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <p>目录</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
// import hljs from "highlight.js";
import { store } from "../store";
import ScrollText from "../components/ScrollText";
// import "highlight.js/styles/atom-one-dark-reasonable.css";

export default {
  data() {
    return {
      search: "",
      externalLink: {
        // hljs_css: function(css) {
        // 这是你的代码高亮配色文件路径
        // return require(`highlight.js/styles/${css}.css`);
        // }
      },
      id: "",
      createAt: null,
      updateAt: null,
      title: "",
      tags: [],
      author: "",
      content: "",
    };
  },
  components: { ScrollText },
  computed: {},
  mounted() {
    console.log("mounted");
    // 切换主题
    store.setTheme("light");
    this.fetchInfo(this.$route.params.id);
    // hljs.initHighlightingOnLoad();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("L");
    },
    fetchInfo(blogId) {
      const host = process.env.VUE_APP_HOST_URL;
      this.axios
        .get(host + "/note/get", {
          params: { id: blogId },
        })
        .then((response) => {
          let result = response.data.data;
          this.id = blogId;
          this.title = result.title;
          this.tags = result.tags;
          this.createAt = result.createAt;
          this.updateAt = result.updateAt;
          this.author = result.author;
          this.content = result.content;
          // this.$notify({
          //   title: "加载文章列表",
          //   message: ""
          // });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  background-color: #fff;
}
.text {
  padding: 0px 10px;
  background-color: #fff;
}
.nav {
  padding: 0px 10px;
}
.blog-title {
  font-size: 2rem;
  &:hover {
    &::before {
      float: left;
      position: absolute;
      left: -1.3rem;
      line-height: 1.4;
      content: "#";
    }
  }
}
.blog-create-date {
  font-size: 0.8rem;
  color: #aaa;
}
// .blog-content {
// background-color: #fafafa;
// border-radius: 5px;
// padding: 10px 10px;
// min-height: 60vh;
// line-height: 1.8;
// font-size: 15px;
// }
.blog-tag {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #007942;
  border: none;
  background-color: #e9f3ef;
}
.zan {
  display: table;
  // margin: auto;
  height: 40px;
  width: 60px;
  border-radius: 20px;
  font-size: 1.2rem;
  padding: 0px 12px;
  margin: 10px auto;
}

/* 覆盖默认样式 */
#editor {
  border: none;
  font-size: 15px;
  line-height: 1.8;
  /deep/ .hljs {
    padding: 5px 10px;
    line-height: 1.3;
    box-sizing: border-box;
    font-size: 18px;
  }
  /deep/ .v-show-content {
    padding: 0px;
  }
  /deep/ .v-note-wrapper {
    border: none;
  }
  /deep/ pre {
    padding: 6px;
    background-color: #fafafa;
    border-radius: 6px;
  }
}
</style>
